<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mt-4.mb-5.lh-18
		h1.mb-6.text-center.d-block(v-html="$t('partnersAgreement.title2')")
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ $t(`agency.published`) }}

		p.mb-4(v-html="$t('partnersAgreement.desc')")
		p.mb-4(v-html="$t('partnersAgreement.desc2')")
		p.mb-4(v-html="$t('partnersAgreement.desc3')")

		ul.list-decimal
			li(v-for='i in list' :key='i.title')
				span
					b(v-if='i.title') {{ i.title }}&nbsp;
					span(v-html='i.text')
		br
		br
		pw-btn-up
</template>

<script>
import CompanyData from '@/components/Common/Company'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import moment from 'moment'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('partnersAgreement.title')}},
	components: {
		CompanyData,
		PwBtnUp
	},
	data () {
		
	},
	computed: {
		list () {
			let list = _.cloneDeep(bus.$t('partnersAgreement.list'));

			return list;
		}
	},
	created() {
		window.scrollTo(0, 0);
	}
}
</script>
